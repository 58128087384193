.MuiSlider-marked {
  margin-bottom: 0 !important;
}

.MuiTableCell-root {
  vertical-align: inherit !important;
  line-height: 0 !important;
}

label {
  margin-bottom: 0 !important;
}

.MuiTypography-subtitle1 {
  line-height: 0 !important;
}

#kt_content {
  overflow-y: auto;
}

// margin xs
.m-xs {
  margin: 5px;
}

.m-x-xs {
  margin-left: 5px;
  margin-right: 5px;
}

.m-y-xs {
  margin-top: 5px;
  margin-bottom: 5px;
}

.m-l-xs {
  margin-left: 5px;
}

.m-r-xs {
  margin-right: 5px;
}

.m-t-xs {
  margin-top: 5px;
}

.m-b-xs {
  margin-bottom: 5px;
}


// margin sm
.m-sm {
  margin: 10px;
}

.m-x-sm {
  margin-left: 10px;
  margin-right: 10px;
}

.m-y-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.m-l-sm {
  margin-left: 10px;
}

.m-r-sm {
  margin-right: 10px;
}

.m-t-sm {
  margin-top: 10px;
}

.m-b-sm {
  margin-bottom: 10px;
}

// margin md
.m-md {
  margin: 15px;
}

.m-x-md {
  margin-left: 15px;
  margin-right: 15px;
}

.m-y-md {
  margin-top: 15px;
  margin-bottom: 15px;
}

.m-l-md {
  margin-left: 15px;
}

.m-r-md {
  margin-right: 15px;
}

.m-t-md {
  margin-top: 15px;
}

.m-b-md {
  margin-bottom: 15px;
}

// margin lg
.m-lg {
  margin: 20px;
}

.m-x-lg {
  margin-left: 20px;
  margin-right: 20px;
}

.m-y-lg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.m-l-lg {
  margin-left: 20px;
}

.m-r-lg {
  margin-right: 20px;
}

.m-t-lg {
  margin-top: 20px;
}

.m-b-lg {
  margin-bottom: 20px;
}

// padding xs
.p-xs {
  padding: 5px;
}

.p-x-xs {
  padding-left: 5px;
  padding-right: 5px;
}

.p-y-xs {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-r-xs {
  padding-right: 5px;
}

.p-l-xs {
  padding-left: 5px;
}

.p-t-xs {
  padding-top: 5px;
}

.p-b-xs {
  padding-bottom: 5px;
}

// padding sm
.p-sm {
  padding: 10px;
}

.p-x-sm {
  padding-left: 10px;
  padding-right: 10px;
}

.p-y-sm {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-r-sm {
  padding-right: 10px;
}

.p-l-sm {
  padding-left: 10px;
}

.p-t-sm {
  padding-top: 10px;
}

.p-b-sm {
  padding-bottom: 10px;
}

// padding md
.p-md {
  padding: 15px;
}

.p-x-md {
  padding-left: 15px;
  padding-right: 15px;
}

.p-y-md {
  padding-top: 15px;
  padding-bottom: 15px;
}

.p-r-md {
  padding-right: 15px;
}

.p-l-md {
  padding-left: 15px;
}

.p-t-md {
  padding-top: 15px;
}

.p-b-md {
  padding-bottom: 15px;
}

// padding lg
.p-lg {
  padding: 20px;
}

.p-x-lg {
  padding-left: 20px;
  padding-right: 20px;
}

.p-y-lg {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-r-lg {
  padding-right: 20px;
}

.p-l-lg {
  padding-left: 20px;
}

.p-t-lg {
  padding-top: 20px;
}

.p-b-lg {
  padding-bottom: 20px;
}


.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
  padding-right: 0!important;
  padding-left: 0!important;
}

.row {
  margin-left: 0!important;
  margin-right: 0!important;
}


.component-loading {
  height: 23px;
}

.list-table-custom {
  .MuiTableCell-root {
    padding: 16px 5px;
  }
}

.layout {
  min-width: 1114px;
  overflow: auto;
}

